.white-info {
  background-color: $neutral100;
  width: 60%;
  padding: 9vh 3vh;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 6vh 3vh 9vh;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-md) {
    padding: 3vh 0;
  }

  .title {
    font-size: 7vh;
    font-weight: 900;
    line-height: 100%;

    width: 88%;
    margin: 0 6% 35px;

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
      margin: 0 0 60px;
      text-align: center;
    }

    @media screen and (max-width: $breakpoint-md) {
      font-size: 40px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 32px;
    }
  }

  .paragraph {
    font-size: 3.75vh;
    line-height: 80px;
    font-weight: 400;
    line-height: 130%;

    margin: 2.5% 2% 0;
    padding: 20px 30px 0;

    @media screen and (max-width: $breakpoint-lg) {
      margin: 2.5% 2% 0;
      font-size: 33px;
    }

    @media screen and (max-width: $breakpoint-md) {
      margin: 7.5% 0 0;
      font-size: 22px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 18px;
    }

    span {
      display: block;
      font-size: 2.5vh;
      line-height: 3.5vh;
      padding-left: 10px;
      padding-top: 35px;
    }

    svg {
      transform: scale(3.5);
      margin-right: 60px;
      margin-left: 30px;
    }
  }
}

#we-are-the-light {
  width: 40%;
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.we-are-the-light {
  width: 100%;
  height: 100%;
  display: block;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}
