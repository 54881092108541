.contact-box {
  text-align: center;
  font-size: 75px;
  padding: 30px 7.5%;
  width: 85%;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 50px;
  }

  .email-box {
    padding: 20px;
    font-size: 30px;
    line-height: 30px;
    height: 30px;
    margin: auto;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 20px;
    }

    svg {
      transform: scale(2);
      padding: 4px 20px 0;
      cursor: pointer;
      line-height: 30px;
    }
  }

  .flex {
    width: 100%;
    padding: 30px 0;
    gap: 5%;

    @media screen and (max-width: $breakpoint-md) {
      gap: 50px;
    }

    display: flex;
    text-align: center;
    justify-content: center;

    svg {
      transform: scale(2);
      cursor: pointer;
    }
  }
}
