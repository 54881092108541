.client-page-nav-bar {
  width: 100%;

  .drawer-menu {
    padding-right: 20px;
  }

  .MuiToolbar-root {
    min-height: 60px;

    background-image: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.98)
    );
    color: $neutral1000;
  }

  .title {
    color: $neutral;
    padding: 10px 0;
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;

    margin-left: 15px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-left: 0;
    }
  }

  .d-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;

    margin: auto;
    margin-right: 1%;

    gap: 0 15px;

    a,
    button {
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      text-transform: none;
    }
  }
}

.header-nav-bar.theme-light {
  .d-flex {
    a,
    button {
      color: $neutral800;
    }
  }

  .title {
    color: $neutral800;
  }
}
