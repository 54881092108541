@import './parts/NavMenu';
@import './parts/NavBar';
@import './parts/Drawer';
@import './parts/BreadcrumbBar';
@import './parts/SearchInput';
@import './parts/UserMenu';

.page-overlay {
  width: 100%;
  height: calc(100vh);
  display: flex;

  .page-content {
    transition: 0.3s width;
    width: calc(100% - 300px);
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $yellow100;

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }

    .page-body {
      transition: 0.3s width;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      margin: 10px;
    }

    .MuiPaper-root {
      position: static;
      box-shadow: none !important;
    }
  }
}

@media screen and (min-width: calc($breakpoint-lg + 1px)) {
  .page-overlay.minimized {
    .page-content {
      width: calc(100% - 100px);
    }

    .main-menu-nav {
      width: 100px;
    }

    .main-menu {
      width: 100px;

      .menu-text {
        display: none;
      }
    }
  }
}
