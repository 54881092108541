.chip-select {
  @include base-input();

  .chip {
    height: 32px;
    width: 150px;
    font-size: 15px;
    line-height: 15px;
    border-radius: 5px;

    .MuiChip-label {
      color: $neutral900;
      font-weight: 500;
    }
  }

  .close-icon {
    margin-right: 32px;
    font-size: 17px;
    fill: $neutral600;
    cursor: pointer;
  }

  .MuiChip-label {
    font-size: 15px;
    margin-left: 5px;
  }
}

.chip-select:not(.has-value) {
  @include has-not-value();
}

.chip-select.has-value {
  @include has-value();

  .MuiInputBase-input {
    padding: 9.5px 16px;
  }
}

.chip-select.has-error {
  @include has-error();
}
