@mixin has-value {
  .MuiOutlinedInput-notchedOutline {
    border-color: $yellow700 !important;
    border-width: 1px !important;
  }

  .MuiFormLabel-root {
    color: $yellow700 !important;
    font-size: 16.5px;
    line-height: 17px;
  }
}

@mixin has-value-file {
  border-color: $yellow700 !important;
  border-width: 1px !important;

  .input-file {
    color: $yellow700 !important;
    font-size: 16.5px;
    line-height: 17px;
  }
}

@mixin has-not-value {
  .MuiFormLabel-root:not(.Mui-focused) {
    transform: translate(16px, 15px) scale(1);
  }
}
