.landing-page {
  .image-group-box {
    width: 90%;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
  }
}
