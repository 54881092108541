#location-form {
  background-color: $neutral;
  padding: 20px;
  border-radius: 4px;

  .MuiTypography-root {
    margin-top: 15px;
    margin-bottom: 6px;
    margin-left: 12px;
  }

  .maps-link {
    position: absolute;
    margin-top: -15px;
    margin-left: 14px;
  }

  .d-flex {
    width: 100%;
    display: flex;
  }

  .uc-location {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;

    .MuiFormControl-root,
    .MuiFormGroup-root,
    .MuiButtonBase-root {
      flex: calc(50% - 20px);
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-lg) {
      .MuiFormControl-root,
      .MuiFormGroup-root,
      .MuiButtonBase-root {
        flex: calc(50% - 10px);
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      .MuiFormControl-root,
      .MuiFormGroup-root,
      .MuiButtonBase-root {
        flex: calc(100%);
      }
    }
  }
}
