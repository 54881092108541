.image-with-footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-md) {
    width: 50%;
  }

  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }

  img {
    border-radius: 100%;
    width: 18vw;
    height: 18vw;
    margin: auto;

    @media screen and (max-width: $breakpoint-md) {
      width: 125px;
      height: 125px;
    }
  }

  .footer {
    width: 100%;
    padding: 36px 0;
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 20px;
      padding: 24px 0;
    }
  }
}

.image-with-footer.theme-dark {
  color: $neutral100;

  img {
    border: 4px solid $neutral600;

    @media screen and (max-width: $breakpoint-md) {
      border: 2px solid $neutral600;
    }
  }
}

.image-with-footer.theme-light {
  color: $neutral800;

  img {
    border: 4px solid $neutral600;

    @media screen and (max-width: $breakpoint-md) {
      border: 2px solid $neutral600;
    }
  }
}
