.header-nav-bar {
  width: 100%;
  padding-top: 54px;

  .MuiPaper-root {
    box-shadow: 0px 1px 2px -1px rgb(0 0 0 / 20%),
      0px 2px 3px 0px rgb(0 0 0 / 14%), 0px 1px 6px 0px rgb(0 0 0 / 12%) !important;
  }

  .MuiToolbar-root {
    min-height: 54px;
  }

  .title {
    color: $neutral;
    padding: 10px 0;
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;

    margin-left: 2.5%;

    @media screen and (max-width: $breakpoint-lg) {
      margin-left: 0;
    }
  }

  .menu-icon {
    display: none;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
      margin: auto;
      margin-right: 0;
    }
  }

  .d-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;

    margin: auto;
    margin-right: 1%;

    gap: 0 15px;

    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }

    a,
    button {
      color: $neutral;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      text-transform: none;
    }
  }
}

.header-nav-bar.theme-light {
  .d-flex {
    a,
    button {
      color: $neutral800;
    }
  }

  .title {
    color: $neutral800;
  }
}
