.input-file-aws {
  @include base-input-file();

  .input-file {
    text-transform: none !important;
    color: $neutral600 !important;
    display: flex !important;
    justify-content: left !important;
    gap: 12px;

    .flex {
      gap: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      line-height: 35px;
      padding: 0 10px;
    }

    input[type='file']::file-selector-button {
      display: none;
    }
  }
}

.file-preview {
  max-height: 250px;
  width: fit-content;
  max-width: calc(100% - 28px);

  padding: 0 14px;
  margin-top: 15px;
}

.input-file-aws.has-value {
  @include has-value-file();
}

.input-file-aws.has-error {
  @include has-error-file();
}
