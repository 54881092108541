@mixin has-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: $red600 !important;
    border-width: 1px !important;
  }

  .MuiFormLabel-root {
    color: $red600 !important;
  }
}

@mixin has-error-file {
  border-color: $red600 !important;
  border-width: 1px !important;

  .input-file {
    color: $red600 !important;
  }
}
