@mixin base-input {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .MuiInputBase-input {
    padding: 14px 16px;
  }

  .Mui-focused.MuiFormLabel-root {
    color: $yellow700 !important;
    font-size: 16.5px;
    line-height: 17px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $yellow700 !important;
    border-width: 1px !important;
  }
}

@mixin base-input-file {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  border: 1px solid $neutral400 !important;
  border-radius: 5px;

  .MuiButtonBase-root {
    padding: 7px 5px;
  }

  .Mui-focused.MuiFormLabel-root {
    color: $yellow700 !important;
    font-size: 16.5px;
    line-height: 17px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $yellow700 !important;
    border-width: 1px !important;
  }
}
