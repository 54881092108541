.avatar-menu {
  .avatar {
    width: 35px;
    height: 35px;
    display: flex;
    background-color: $yellow500;
  }

  .avatar:nth-child(1) {
    font-size: 15px;
    line-height: 15px;
    padding-left: 2px;
    padding-top: 2px;
  }
}
