.location-input {
  @include base-input();

  #map {
    height: 200px;
    width: 100%;
    border: 0 !important;
  }
}

.location-input:not(.has-value) {
  @include has-not-value();
}

.location-input.has-value {
  @include has-value();
}

.location-input.has-error {
  @include has-error();
}
