.depositions-box {
  background-color: $neutral;
  padding: 44px 15%;
  width: 70%;

  @media screen and (max-width: $breakpoint-ld) {
    padding: 30px 7.5%;
    width: 85%;
  }

  .flex {
    display: flex;
    margin: auto;
    width: 100%;
    gap: 70px;

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      margin-bottom: 0px;
      gap: 30px;
    }
  }

  .title {
    font-size: 7vh;
    font-weight: 900;
    line-height: 110%;
    text-align: center;

    padding-bottom: 40px;

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-md) {
      font-size: 40px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 32px;
    }
  }

  .card-title {
    text-align: left;
    padding-left: 30px;
  }
}
