#dashboard-page {
  .projects-title {
    font-size: 30px;
    line-height: 30px;

    padding: 10px 7px 10px;
  }

  .d-flex {
    display: flex;
    gap: 10px;
    width: 100%;

    @media screen and (max-width: $breakpoint-lg) {
      flex-grow: 2;
      flex-wrap: wrap;
      .indicator-card {
        width: calc(50% - 5px);
      }
    }

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: column;

      .indicator-card {
        width: 100%;
      }
    }
  }
}
