.box-with-title-footer {
  width: 100%;

  .title {
    width: 90%;
    padding: 60px 5%;
    font-weight: 900;
    font-size: 7.5vh;
    text-align: center;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 40px;
      padding: 30px 5%;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 32px;
    }
  }

  .footer {
    width: 90%;
    padding: 60px 5% 100px;
    font-weight: 600;
    font-size: 6.4vh;
    text-align: center;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 40px;
      padding: 30px 5%;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 32px;
    }
  }
}

.box-with-title-footer.theme-dark {
  color: $neutral100;
  background-color: $neutral900;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  );
}
