.login-page {
  width: 100vw;
  height: 95vh;
  display: flex;
  background-image: linear-gradient(to bottom right, $yellow100, $yellow300);
  padding-bottom: 5vh;

  .body {
    width: 350px;
    padding: 60px 30px 50px;
    border-radius: 5px;
    background-color: $neutral;

    margin: auto;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .icon {
      text-align: center;
    }

    .links {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
    }

    .remember {
      padding: 5px 0 10px;
    }

    .back-to {
      padding: 10px 0;
    }
  }
}
