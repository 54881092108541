.breadcrumb-bar {
  padding: 12px 20px;
  background-color: $neutral200;
  a,
  p {
    font-weight: 400;
    color: $neutral900;
    font-size: 15px;
  }

  .MuiTypography-root {
    text-decoration: none;
  }
}
