@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Broadleaf-Regular';
  src: url('./fonts/Broadleaf-Regular.ttf') format('truetype'),
    url('./fonts/Broadleaf-Regular.woff2') format('woff2'),
    url('./fonts/Broadleaf-Regular.woff') format('woff');
}

* {
  font-family: 'Broadleaf-Regular' !important;
  letter-spacing: 1px !important;
  scroll-behavior: smooth !important;
  scroll-margin-top: 70px;
}

body {
  margin: 0;
  font-family: 'Broadleaf-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Broadleaf-Regular';
}

@import './mixins';
@import './colors';
@import './breakpoints';

// Layouts
@import '../layouts/LandingPage';
@import '../layouts/MainPage';

// Pages
@import '../pages/LandingPage';
@import '../pages/LoginPage';
@import '../pages/AccountingPage';
@import '../pages/DashboardPage';
@import '../pages/AddProjectPage/presentation';

// Components
@import '../components/BoxWithTitleFooter';
@import '../components/ImageWithFooter';
@import '../components/CardWithPicture';
@import '../components/IndicatorCard';
@import '../components/FileInputAws';
@import '../components/TextField';
@import '../components/DateSelect';
@import '../components/SnackBar';
@import '../components/ChipSelect';
@import '../components/LocationInput';
