@import '../components/Steps/Identify';
@import '../components/Steps/TechnicalData';
@import '../components/Steps/Location';
@import '../components/Steps/Photos';

@import '../components/Stepper';

#add-project-page {
  border-radius: 4px;
  width: 100%;
  padding-bottom: 75px;

  .action-buttons {
    border-top: 1px solid $neutral400;
    position: fixed;
    z-index: 100;
    bottom: 0px;
    width: calc(100vw - 318px);

    display: flex;
    align-items: center;
    justify-content: flex-end;

    background-color: $neutral;

    border-radius: 0px;
    padding: 7px 9px;
    gap: 0 25px;
    margin-left: -10px;

    @media screen and (max-width: $breakpoint-lg) {
      width: calc(100vw - 18px);
    }

    .submit-button {
      background-color: $yellow600;
    }

    .submit-button:hover {
      background-color: $orange600;
    }

    button {
      width: 20%;
      min-width: 300px;
      border-radius: 3px;

      @media screen and (max-width: $breakpoint-lg) {
        width: 100%;
      }

      height: 38px;
      line-height: 38px;

      font-size: 16px;
      font-weight: 600;
      text-transform: none;

      .MuiLoadingButton-loadingIndicator {
        color: $neutral;
      }
    }
  }
}

.page-overlay.minimized {
  #add-project-page {
    .action-buttons {
      width: calc(100vw - 118px);

      @media screen and (max-width: $breakpoint-lg) {
        width: calc(100vw - 18px);
      }
    }
  }
}
