.card-with-picture {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 80px;

  img {
    border-radius: 100%;
    height: 150px;
    width: 150px;
    margin: auto;
    object-fit: cover;
    margin-top: -100px;

    @media screen and (max-width: $breakpoint-md) {
      height: 125px;
      width: 125px;
    }
  }

  .header {
    background-color: $neutral;
    border-radius: 15px;
    padding: 20px 0;
    width: 100%;
    gap: 15px;

    margin: 0 auto;
    display: flex;
    text-align: center;
    flex-direction: column;
  }

  .card-title {
    font-size: 34px;
    line-height: 34px;
    font-weight: 700;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .paragraph {
    text-align: left;
    word-wrap: break-word;

    width: 100%;
    padding: 36px 10px;

    font-size: 24px;
    font-weight: 500;

    @media screen and (max-width: $breakpoint-md) {
      padding: 24px 8px 0;
      font-size: 20px;
    }

    p {
      margin-top: 0;
    }
  }
}

.card-with-picture.theme-dark {
  color: $neutral900;

  img {
    border: 3px solid $neutral800;

    @media screen and (max-width: $breakpoint-md) {
      border: 2px solid $neutral800;
    }
  }
}

.card-with-picture.theme-light {
  color: $neutral900;

  .header {
    background-color: $stop;
  }

  img {
    border: 3px solid $neutral800;

    @media screen and (max-width: $breakpoint-md) {
      border: 2px solid $neutral800;
    }
  }
}
