.steps-non-linear {
  background-color: $neutral;
  padding: 35px 0 0;
  border-radius: 4px;

  @media screen and (max-width: $breakpoint-xs) {
    .MuiStepper-root {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex: 2;
      gap: 15px 0;
    }

    .MuiStep-root {
      flex: calc(50% - 20px);
    }

    .MuiStepConnector-root {
      display: none;
    }
  }

  .visible {
    display: inherit;
  }

  .invisible {
    display: none;
  }

  .MuiStepIcon-text::before {
    margin-left: 20px !important;
  }

  .MuiStepLabel-root {
    cursor: pointer;
  }

  .Mui-completed {
    color: $yellow500 !important;
  }

  .Mui-active {
    color: $yellow500 !important;
    font-weight: 600 !important;
  }
}
