.yellow-info {
  background-color: $stop;
  width: 55%;
  padding: 9vh 0;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 6vh 0 9vh;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-md) {
    padding: 3vh 0;
  }

  .title {
    font-size: 7vh;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    margin: 0 5% 20px;

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
      margin: 0;
    }

    @media screen and (max-width: $breakpoint-md) {
      font-size: 40px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 32px;
    }
  }

  .paragraph {
    font-size: 3.75vh;
    line-height: 80px;
    font-weight: 400;
    line-height: 120%;

    display: list-item;
    list-style-type: disc;
    list-style-position: outside;

    width: 80%;
    margin-left: calc(10% + 30px);
    padding: 30px 0 15px;

    svg {
      transform: scale(3.5);
      margin-right: 60px;
      margin-left: 30px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 33px;
    }

    @media screen and (max-width: $breakpoint-md) {
      margin-left: calc(10% + 15px);
      font-size: 22px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 20px;
    }
  }
}

#analytics-phone {
  width: 45%;
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.analytics-phone {
  width: 100%;
  height: 100%;
  display: block;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}
