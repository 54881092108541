.environment-indicator {
  position: fixed;
  bottom: -53px;
  left: -155px;
  background-color: red;
  color: white;
  padding: 25px 155px;
  transform-origin: bottom right;
  transform: rotate(45deg);
  font-weight: bold;
  z-index: 9999; 
  animation: blink-animation 3s infinite;
}

.prod {
  display: none; /* Hide indicator in production environment */
}

.hml {
  background-color: red; /* Change color for development environment */
}

.dev {
  background-color: gray; /* Change color for development environment */
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.45;
  }
  100% {
    opacity: 1;
  }
}