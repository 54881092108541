.indicator-card {
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  background-color: $neutral;

  .indicator-chip {
    height: 37px;
    width: calc(100% - 20px);
    font-size: 18px;
    line-height: 20px;
    border-radius: 4px !important;

    .MuiChip-label {
      font-weight: 700 !important;
    }
  }

  .indicator-title {
    height: calc(100% - 10px);

    .label {
      text-align: center;
      font-size: 70px;
      line-height: 100px;
      padding: 15px 0 0;
      font-weight: 600;
    }

    .title {
      text-align: center;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .progress-bar {
    padding: 0 10px;

    .bar-label {
      margin-left: 3px;
    }

    .MuiLinearProgress-root {
      margin: 5px 0;
      border-radius: 2.5px;
      height: 7px;
    }
  }
}
