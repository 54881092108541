@import '@radix-ui/colors/yellow.css';

.ModalProjectInfoContent {
  overflow: auto;
}

.ButtonClose {
  color: var(--yallow-11) !important;
}
.ButtonClose:hover {
  background-color: var(--yellow-4);
}
.ButtonClose:focus {
  box-shadow: 0 0 0 2px var(--yellow-7);
}

.LoadingFile {
  cursor: wait;
}
