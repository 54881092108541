$blue100: #ecfcfd;
$blue200: #b7eef5;
$blue300: #84dbeb;
$blue400: #54c4de;
$blue500: #25a8d0;
$blue600: #2282a8;
$blue700: #1e5f82;
$blue800: #18415d;
$blue900: #102739;

$green100: #eaf3e2;
$green200: #cbe3b5;
$green300: #add585;
$green400: #91c854;
$green500: #78be20;
$green600: #41a017;
$green700: #158110;
$green800: #0a601b;
$green900: #053e1f;

$neutral: #ffffff;
$neutral100: #ebebed;
$neutral200: #d3d2d6;
$neutral300: #c9c8cc;
$neutral400: #bab6bc;
$neutral500: #887f87;
$neutral600: #6f676c;
$neutral700: #2e2e2d;
$neutral800: #222020;
$neutral900: #121212;
$neutral1000: #000000;

$orange100: #fff0df;
$orange200: #ffdab2;
$orange300: #ffc170;
$orange400: #ffab48;
$orange500: #fa931d;
$orange600: #f9861c;
$orange700: #974f20;
$orange800: #6a351c;
$orange900: #401f15;

$red100: #feedee;
$red200: #fab9bc;
$red300: #f38789;
$red400: #e55954;
$red500: #df382b;
$red600: #cf3429;
$red700: #8b2226;
$red800: #641b21;
$red900: #3e1219;
$red1000: #df3828;

$violet100: #e9d0ff;
$violet200: #c48ff3;
$violet300: #ad63f0;
$violet400: #9040e6;
$violet500: #7808cf;
$violet600: #6c0cae;
$violet700: #570887;
$violet800: #480870;
$violet900: #350c52;

$yellow100: #fffbe8;
$yellow200: #ffe9b6;
$yellow300: #fffb8a;
$yellow400: #fcd64c;
$yellow500: #eaba07;
$yellow600: #dcaf07;
$yellow700: #d2a405;
$yellow800: #705613;
$yellow900: #46350d;

$secondary: #9c27b0;
$primary: #1976d2;
$warning: #ed6c02;
$success: #2e7d32;
$stop: #e6fe28;
