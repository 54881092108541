@import './parts/ServicesBox/index.scss';
@import './parts/RightInfoBox/index.scss';
@import './parts/LeftInfoBox/index.scss';
@import './parts/FoundersBox/index.scss';
@import './parts/DepositionsBox/index.scss';
@import './parts/ContactBox/index.scss';

.landing-page {
  .cover-sheet {
    width: 100%;
    display: block;
  }

  .welcome-to-sun-energy {
    width: 100%;
    display: block;
  }
}
