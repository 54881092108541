.main-menu {
  transition: 0.3s width !important;
  overflow: hidden;
  width: 299px;
  height: 100%;
  border-radius: 0 !important;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.09),
    rgba(255, 255, 255, 0.09)
  ) !important;

  .menu-title {
    word-wrap: break-word;
    padding: 17px 0 !important;
    text-align: center;

    h6 {
      width: 100%;
      font-size: 17px;
      line-height: 17px;
    }
  }

  .MuiMenuItem-root {
    padding: 20px 10px 20px 35px;
    overflow: visible;

    span {
      margin-top: 2px;

      font-weight: 600;
      font-size: 14px;
      color: $neutral;
    }
  }

  .minimize-menu {
    transition: 0.3s all;
    position: fixed;
    margin-top: -10px;
    left: 289px;

    width: 21px;
    height: 21px;
    border-radius: 4px;

    background-color: $yellow500;
    color: $neutral;

    cursor: pointer;

    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }
}

.minimized {
  .minimize-menu {
    left: 89px;
    transform: rotate(-180deg);
  }
}
