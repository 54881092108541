.main-menu-nav {
  transition: 0.3s width;
  width: 300px;
  height: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    width: 0;
  }
}

.nav-menu-drawer {
  display: block;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.drawer-menu {
  display: none !important;

  @media screen and (max-width: $breakpoint-lg) {
    display: inherit !important;
  }
}

.mobile {
  display: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}
